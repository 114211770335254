(function($) {
    'use strict';



/**
     * Add a 'Back' link to sub menus
     */
    $('.menu__item--back').on('click', function(e) {
        $(this).closest('.is-active').removeClass('is-active');
        $(this).parents('nav').removeClass('is-active');
        $('.c-offcanvas__sidebar').show();
    });

    $('.c-nav-primary .sub-menu .menu__item').hide();



    $(".s-page-wrap .c-booking-bar").parents(".panel-grid").addClass("contains-booking-bar");


})(jQuery);